import React from 'react';
import './Table2.css';

const HintsTable = () => {
  return (
    <div className="table-container">
      <h3>Hints</h3>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>ID</th>
            <th>Hint</th>
            <th>Time</th>
            <th>Dynamic</th>
            <th>Used</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>E1A1H1</td>
            <td>What is the possible IP address...</td>
            <td>8'</td>
            <td>✔</td>
            <td>✔</td>
          </tr>
          <tr>
            <td>2</td>
            <td>E1A1H2</td>
            <td>Tool suggested</td>
            <td>12'</td>
            <td> </td>
            <td>✔</td>
          </tr>
          <tr>
            <td>3</td>
            <td>E2A2H3</td>
            <td>Command with appropriate flags</td>
            <td>15'</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>E3A2H4</td>
            <td>Privilege escalation technique using GTFOBins</td>
            <td>25'</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HintsTable;
