import React from 'react';
import './Table.css';

const Machine3Table = () => {
  return (
    <div className="table-container">
      <h3>Machine 3 LinuxDesktop-VM-09-Ubuntu</h3>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>Name of Vulnerability</th>
            <th>CVSS Score</th>
            <th>Found</th>
            <th>Patched</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>CWE-787 Out-of-bounds Write</td>
            <td>9.8</td>
            <td className="fail">Fail</td>
            <td className="fail">Fail</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>CWE-476 NULL Pointer Dereference</td>
            <td>7.5</td>
            <td className="success">Success</td>
            <td className="success">Success</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>CWE-79 Improper Neutralization of Input (XSS)</td>
            <td>4.3</td>
            <td className="success">Success</td>
            <td className="success">Success</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Machine3Table;
