import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import YourServices from './pages/YourServices/YourServices';
import Unit3 from './pages/Unit3/Unit3';
import SituationalAwareness from './pages/Unit3/SituationalAwareness';
import Scoring from './pages/Unit3/Scoring';
import Strategy from './pages/Unit3/Strategy';
import FinalReport from './pages/Unit3/FinalReport';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/your-services" element={<YourServices />} />
        <Route path="/unit3" element={<Unit3 />} />
        <Route path="/unit3/situational-awareness" element={<SituationalAwareness />} />
        <Route path="/unit3/scoring" element={<Scoring />} />
        <Route path="/unit3/strategy" element={<Strategy />} />
        <Route path="/unit3/final-report" element={<FinalReport />} />
      </Routes>
    </Router>
  );
};

export default App;

