import React, { useState } from 'react';
import './Notifications.css';

export const Notifications: React.FC = () => {
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div className="notifications-container">
      <button className="notifications-button" onClick={toggleNotifications}>
        Notifications <span className="badge">8</span>
      </button>
      {showNotifications && (
        <div className="notifications-dropdown">
          <p>You have 8 notifications.</p>
          {/* Εδώ μπορείς να προσθέσεις περισσότερες πληροφορίες για τις ειδοποιήσεις */}
        </div>
      )}
    </div>
  );
};
