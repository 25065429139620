import React, { useState, useEffect, useRef } from 'react';
import './Dropdown.css'; 

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <button 
        className={`dropdown-button ${isOpen ? 'active' : ''}`} 
        onClick={toggleDropdown}
      >
        Emilia Manager <span>{isOpen ? '▲' : '▼'}</span>
      </button>

      {isOpen && (
        <div className="dropdown-menu">
          <a href="#settings" className="dropdown-item">
            <i className="icon-settings"></i> Settings
          </a>
          <a href="#help" className="dropdown-item">
            <i className="icon-help"></i> Help
          </a>
          <a href="#logout" className="dropdown-item">
            <i className="icon-logout"></i> Log out
          </a>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
