import React from 'react';
import { Notifications } from '../Notifications/Notifications';
import Dropdown from '../Dropdown/Dropdown';
import { Link } from 'react-router-dom';
import './Header.css'; 

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="logo-section">
        <img src="/logo.png" alt="logo" className="logo" />
        
      </div>
      <div className="nav-section">
        <Link to="/your-services" className="nav-link">Your Services</Link>
        <Notifications />
        <Dropdown />
      </div>
    </header>
  );
};

export default Header;
