import React, { useEffect, useState } from 'react';
import cytoscape from 'cytoscape';
import './Strategy.css';

const App: React.FC = () => {
  const [cyInstance, setCyInstance] = useState<any>(null);
  const [isOptimalHighlighted, setIsOptimalHighlighted] = useState(false);
  const [isMyPathHighlighted, setIsMyPathHighlighted] = useState(false);

  useEffect(() => {
    const elements = [
      // LinuxSrv-VM-02 
      { data: { id: 'LinuxSrv-VM-02', label: 'LinuxSrv-VM-02' } }, // Parent container
      { data: { id: 'node1', label: '3', parent: 'LinuxSrv-VM-02' }, position: { x: 100, y: 50 } }, // Adjusted positions
      { data: { id: 'node2', label: '4', parent: 'LinuxSrv-VM-02' }, position: { x: 150, y: 100 } },
      { data: { id: 'node3', label: '5', parent: 'LinuxSrv-VM-02' }, position: { x: 200, y: 50 } },
      { data: { id: 'node4', label: '2', parent: 'LinuxSrv-VM-02' }, position: { x: 250, y: 100 } },
      { data: { id: 'node5', label: '1', parent: 'LinuxSrv-VM-02' }, position: { x: 150, y: 0 } },
      { data: { id: 'node6', label: '3', parent: 'LinuxSrv-VM-02' }, position: { x: 150, y: 50 } },
      { data: { source: 'node1', target: 'node2' } },
      { data: { source: 'node4', target: 'node5' } },
      { data: { source: 'node5', target: 'node6' } },

      // LinuxSrv-Docker-CentOS 
      { data: { id: 'LinuxSrv-Docker-CentOS', label: 'LinuxSrv-Docker-CentOS' } }, // Parent container
      { data: { id: 'node7', label: '3', parent: 'LinuxSrv-Docker-CentOS' }, position: { x: 350, y: 50 } }, // Adjusted positions
      { data: { id: 'node8', label: '4', parent: 'LinuxSrv-Docker-CentOS' }, position: { x: 400, y: 100 } },
      { data: { id: 'node9', label: '6', parent: 'LinuxSrv-Docker-CentOS' }, position: { x: 450, y: 50 } },
      

      // LinuxDesktop-VM-09-Ubuntu 
      { data: { id: 'LinuxDesktop-VM-09-Ubuntu', label: 'LinuxDesktop-VM-09-Ubuntu' } }, // Parent container
      { data: { id: 'node10', label: '4', parent: 'LinuxDesktop-VM-09-Ubuntu' }, position: { x: 600, y: 50 } }, // Adjusted positions
      { data: { id: 'node11', label: '6', parent: 'LinuxDesktop-VM-09-Ubuntu' }, position: { x: 650, y: 100 } },
      { data: { id: 'node12', label: '10', parent: 'LinuxDesktop-VM-09-Ubuntu' }, position: { x: 700, y: 50 } },
      { data: { source: 'node10', target: 'node11' } },
      { data: { source: 'node12', target: 'node11' } },

      // Linux-Docker-NodeJS 
      { data: { id: 'Linux-Docker-NodeJS', label: 'Linux-Docker-NodeJS' } }, // Parent container
      { data: { id: 'node13', label: '4', parent: 'Linux-Docker-NodeJS' }, position: { x: 100, y: 350 } }, // Adjusted positions
      { data: { id: 'node14', label: '4', parent: 'Linux-Docker-NodeJS' }, position: { x: 150, y: 400 } },
      { data: { id: 'node15', label: '8', parent: 'Linux-Docker-NodeJS' }, position: { x: 200, y: 350 } },
      { data: { id: 'node16', label: '9', parent: 'Linux-Docker-NodeJS' }, position: { x: 250, y: 400 } },
      { data: { source: 'node13', target: 'node14' } },
      { data: { source: 'node15', target: 'node16' } },

      // WinSrv-VM-04 
      { data: { id: 'WinSrv-VM-04', label: 'WinSrv-VM-04' } }, // Parent container
      { data: { id: 'node17', label: '8', parent: 'WinSrv-VM-04' }, position: { x: 350, y: 350 } }, // Adjusted positions
      { data: { id: 'node18', label: '8', parent: 'WinSrv-VM-04' }, position: { x: 400, y: 400 } },
      { data: { id: 'node19', label: '10', parent: 'WinSrv-VM-04' }, position: { x: 450, y: 350 } },
      { data: { id: 'node20', label: '7', parent: 'WinSrv-VM-04' }, position: { x: 450, y: 400 } },
      { data: { source: 'node17', target: 'node18' } },

      // WinDesktop-VM-06 
      { data: { id: 'WinDesktop-VM-06', label: 'WinDesktop-VM-06' } }, // Parent container
      { data: { id: 'node21', label: '4', parent: 'WinDesktop-VM-06' }, position: { x: 600, y: 350 } }, // Adjusted positions
      { data: { id: 'node22', label: '3', parent: 'WinDesktop-VM-06' }, position: { x: 650, y: 400 } },
      { data: { id: 'node23', label: 'Honeypot', parent: 'WinDesktop-VM-06' }, position: { x: 700, y: 350 } },
      { data: { source: 'node22', target: 'node23' } },

      // Edges between containers
       
      { data: { source: 'node2', target: 'node7' } }, 
      { data: { source: 'node6', target: 'node7' } }, 
      { data: { source: 'node9', target: 'node10' } }, 
      { data: { source: 'node14', target: 'node7' } }, 
      { data: { source: 'node16', target: 'node17' } }, 
      { data: { source: 'node18', target: 'node12' } }, 
      { data: { source: 'node19', target: 'node21' } }, 
      { data: { source: 'node20', target: 'node22' } }, 
      { data: { source: 'node21', target: 'node12' } }, 
    
      //  the optimal path
      { data: { source: 'node3', target: 'node7' } },
      { data: { source: 'node7', target: 'node8' } },
      { data: { source: 'node8', target: 'node9' } },
      { data: { source: 'node9', target: 'node11' } },

      // My path
      { data: { source: 'node13', target: 'node15' } },
      { data: { source: 'node15', target: 'node19' } },
      { data: { source: 'node19', target: 'node23' } },
    ];

    const cy = cytoscape({
      container: document.getElementById('cy'),
      elements: elements,
      style: [
        {
          selector: 'node',
          style: {
            'background-color': '#61bffc',
            'label': 'data(label)',
            'text-valign': 'center',
            'text-halign': 'center',
            'color': '#000',
            'font-size': '12px',
          }
        },
        {
          selector: 'edge',
          style: {
            'width': 2,
            'line-color': '#ccc',
            'target-arrow-color': '#ccc',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
          }
        },
        {
          selector: ':parent',
          style: {
            'background-opacity': 0.2,
            'border-color': '#000',
            'border-width': 2,
            'label': 'data(label)',
            'text-valign': 'top',
            'text-halign': 'center',
            'font-size': '16px',
            'font-weight': 'bold',
          }
        },
        {
          selector: '.optimal-edge',
          style: {
            'line-color': '#ff0000', 
            'target-arrow-color': '#ff0000',
            'target-arrow-shape': 'triangle',
            'width': 4,
          }
        },
        {
          selector: '.my-path-edge',
          style: {
            'line-color': '#0000ff', 
            'target-arrow-color': '#0000ff',
            'target-arrow-shape': 'triangle',
            'width': 4,
          }
        }
      ],
      layout: {
        name: 'preset',
      }
    });

    setCyInstance(cy);
  }, []);

  const toggleOptimalPath = () => {
    if (!cyInstance) return;

    setIsOptimalHighlighted(!isOptimalHighlighted);

    cyInstance.edges('[source="node3"][target="node7"], [source="node7"][target="node8"], [source="node8"][target="node9"], [source="node9"][target="node11"]').toggleClass('optimal-edge', !isOptimalHighlighted);
  };

  const toggleMyPath = () => {
    if (!cyInstance) return;

    setIsMyPathHighlighted(!isMyPathHighlighted);

    cyInstance.edges('[source="node13"][target="node15"], [source="node15"][target="node19"], [source="node19"][target="node23"]').toggleClass('my-path-edge', !isMyPathHighlighted);
  };

  return (
    <div className="App">
      <h1>Scenario Map </h1>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <label style={{ marginRight: '15px' }}>
          Show <span style={{ color: 'red' }}>Optimal</span> Path
          <input type="checkbox" onChange={toggleOptimalPath} />
        </label>
        <label>
          Show <span style={{ color: 'blue' }}>My</span> Path
          <input type="checkbox" onChange={toggleMyPath} />
        </label>
      </div>
      <div id="cy" style={{ width: '100%', height: '500px', border: '1px solid black' }} />
    </div>
  );
};

export default App;
