import React, { useState } from 'react';
import './Table.css';

const EventsTable = () => {
  const [isExpanded, setIsExpanded] = useState<number | null>(null);

  const toggleRow = (row: number) => {
    setIsExpanded(isExpanded === row ? null : row);
  };

  return (
    <div className="table-container">
      <h3>Events</h3>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>No.</th>
            <th>ID</th>
            <th>Inject / Actor</th>
            <th>Release Time</th>
            <th>User Awareness</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>1</td>
            <td>E1</td>
            <td>Apache Server Vulnerability</td>
            <td>5'</td>
            <td><span className="success">✔</span></td>
          </tr>

          <tr>
            <td>
              <button className="toggle-btn" onClick={() => toggleRow(2)}>
                {isExpanded === 2 ? '▴' : '▾'}
              </button>
            </td>
            <td>2</td>
            <td>E2</td>
            <td>Simulated User 1 <span className="red">● Red</span></td>
            <td>10'</td>
            <td><span className="fail">✘</span></td>
          </tr>
          {isExpanded === 2 && (
            <tr>
              <td colSpan={6}>
                <div className="expanded-content">
                  <div className="expanded-row">
                    <div className="column">
                      <p><strong>Description</strong></p>
                      <p>WordPress instance is intentionally vulnerable. User should exploit CVE 2023-2636</p>
                      <p><strong>Machine</strong></p>
                      <p> LinuxSrv-Docker02-CentOS</p>
                      <p><strong>Exploitation</strong></p>
                      <p><span className="failed-box">Failed</span></p>
                      <div className="relations">
                        <label>
                          <strong>Related Actions</strong> 
                          <select className="dropdown-select">
                            <option>E2A1 - Scanning the Network</option>
                            <option>E2A2 - Exploiting a Vulnerability</option>
                            <option>E2A3 - Gaining Shell Access</option>
                            <option>E2A4 - Privilege Escalation</option>
                            <option>E2A5 - Network Traffic Analysis</option>
                            <option>E2A6 - Password Cracking</option>
                            <option>E2A7 - Setting up Persistence</option>
                          </select>
                        </label>
                        <label>
                          <strong>Related Hints</strong>
                          <select className="dropdown-select">
                            <option>Hint 1</option>
                            <option>Hint 2</option>
                            <option>Hint 3</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div className="column">
                      <p><strong>Type</strong> </p>
                      <p><span className="pill">Simulated User</span> 
                        <span className="pill">Reaction</span> 
                        <span className="pill">Timed</span>
                      </p>
                      <p><strong>Time</strong></p>
                      <p>May 27th, 2023, 9:40 (10')</p>
                      <p><strong>Final Report</strong> </p>
                      <p><input type="checkbox" checked /> Include in Final Report
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )}

          <tr>
            <td></td>
            <td>3</td>
            <td>E3</td>
            <td>WordPress CMS Vulnerable Version</td>
            <td>20'</td>
            <td></td>
          </tr>

          <tr>
          <td></td>
            <td>4</td>
            <td>E4</td>
            <td>Simulated User 1 <span className="blue">● Blue</span></td>
            <td>30'</td>
            <td><button className="remove-btn">Remove</button></td>
          </tr>

          <tr>
            <td></td>
            <td>5</td>
            <td>E5</td>
            <td>MySQL SQLInjection Vulnerability</td>
            <td>45'</td>
            <td></td>
          </tr>

          
        </tbody>
      </table>
    </div>
  );
};

export default EventsTable;
