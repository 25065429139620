import React from 'react';
import EventsTable from '../../tables/EventsTable';
import SimulatedUsersTable from '../../tables/SimulatedUsersTable';
import ActionsTable from '../../tables/ActionsTable';
import HintsTable from '../../tables/HintsTable';
import Timeline from '../../components/Timeline/Timeline';
import "./SituationalAwarness.css";

const SituationalAwareness = () => {
  return (
    <div className="timeline-container">
      <div className="timeline-header">
        <div className="timeline-info">
          <h3>Timeline</h3>
          <p className="total-time">Total time: 120 minutes</p>
        </div>
        <div className="duration-info">
          <h4>Duration</h4>
          <div className="duration">
            <span role="img" aria-label="clock">⏱️</span> 00:13:58
          </div>
        </div>
      </div>

      <div className="timeline">
        <Timeline />
      </div>

      <EventsTable />
      <SimulatedUsersTable />
      <ActionsTable />
      <HintsTable />
    </div>
  );
};

export default SituationalAwareness;
