import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import FinalReportPDF from './FinalReportPDF';
import './FinalReport.css';

const FinalReport: React.FC = () => {
  return (
    <div className="final-report-container">
      <h1>Final Report</h1>
      

      <div className="pdf-viewer">
        <PDFViewer width="100%" height="600px">
          <FinalReportPDF />
        </PDFViewer>
      </div>
    </div>
  );
};

export default FinalReport;
