import React from 'react';
import './Table2.css';

const SimulatedUsersTable = () => {
  return (
    <div className="table-container">
      
      <table>
        
        <tbody>
          <tr>
            <td>Simulated User 2</td>
            <td><span className="red">● Red</span></td>
            <td><button className="add-btn">Add</button></td>
          </tr>
          <tr>
            <td>Simulated User 3</td>
            <td><span className="red">● Red</span></td>
            <td><button className="add-btn">Add</button></td>
          </tr>
          <tr>
            <td>Simulated User 2</td>
            <td><span className="blue">● Blue</span></td>
            <td><button className="add-btn">Add</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SimulatedUsersTable;
