import React from 'react';
import { Link } from 'react-router-dom';
import './YourServices.css';

const YourServices = () => {
  return (
    <div className="your-services-container">
      <div className="service-item">
        <div className="service-circle">
          <span>U3</span>
        </div>
        <div className="service-details">
          <p className="service-subtitle">CYBER RANGE SCENARIO</p>
          <h2 className="service-title">Exploiting Linux webServer hands-on training</h2>
        </div>
      </div>
      <Link to="/unit3" className="unit-link">Unit 3</Link>
    </div>
  );
};

export default YourServices;

