import React, { useState } from 'react';
import user from '../../data/user'; 
import './Unit3.css'; 
import SituationalAwareness from './SituationalAwareness';
import Scoring from './Scoring';
import Strategy from './Strategy';
import FinalReport from './FinalReport';

const Unit3 = () => {
  const [activeTab, setActiveTab] = useState('situational-awareness');

  const renderContent = () => {
    switch (activeTab) {
      case 'situational-awareness':
        return <SituationalAwareness />;
      case 'scoring':
        return <Scoring />;
      case 'strategy':
        return <Strategy />;
      case 'final-report':
        return <FinalReport />;
      default:
        return <SituationalAwareness />;
    }
  };

  return (
    <div className="unit3-container">
      <div className="back-link">
        <a href="/your-services">← Unit 3: Exploiting Linux webServer hands-on training</a>
      </div>

      <div className="user-info">
        <img src={user.image} alt={user.name} className="user-image" />
        <div className="user-details">
          <p className="user-role">{user.role}</p>
          <h2 className="user-name">{user.name}</h2>
        </div>
      </div>

      <nav className="menu-tabs">
        <button
          className={activeTab === 'situational-awareness' ? 'active' : ''}
          onClick={() => setActiveTab('situational-awareness')}
        >
          Situational Awareness
        </button>
        <button
          className={activeTab === 'scoring' ? 'active' : ''}
          onClick={() => setActiveTab('scoring')}
        >
          Scoring
        </button>
        <button
          className={activeTab === 'strategy' ? 'active' : ''}
          onClick={() => setActiveTab('strategy')}
        >
          Strategy
        </button>
        <button
          className={activeTab === 'final-report' ? 'active' : ''}
          onClick={() => setActiveTab('final-report')}
        >
          Final Report
        </button>
      </nav>

      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Unit3;
