import React from 'react';
import './Table2.css';

const ActionsTable = () => {
  return (
    <div className="table-container">
      <h3>Actions</h3>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>ID</th>
            <th>Action</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>E1A1</td>
            <td>Information Gathering</td>
            <td>5'</td>
          </tr>
          <tr>
            <td>2</td>
            <td>E1A2</td>
            <td>Enumeration</td>
            <td>7'</td>
          </tr>
          <tr>
            <td>3</td>
            <td>E2A1</td>
            <td>Exploitation</td>
            <td>11'</td>
          </tr>
          <tr>
            <td>4</td>
            <td>E2A2</td>
            <td>Scanning the Network</td>
            <td>14'</td>
          </tr>
          <tr>
            <td>5</td>
            <td>E2A3</td>
            <td>Exploiting a Vulnerability</td>
            <td>17'</td>
          </tr>
          <tr>
            <td>6</td>
            <td>E3A1</td>
            <td>Gaining Shell Access</td>
            <td>20'</td>
          </tr>
          <tr>
            <td>7</td>
            <td>E3A2</td>
            <td>Privilege Escalation</td>
            <td>23'</td>
          </tr>
          <tr>
            <td>8</td>
            <td>E3A3</td>
            <td>Network Traffic Analysis</td>
            <td>25'</td>
          </tr>
          <tr>
            <td>9</td>
            <td>E3A4</td>
            <td>Password Cracking</td>
            <td>27'</td>
          </tr>
          <tr>
            <td>10</td>
            <td>E4A1</td>
            <td>Setting up Persistence</td>
            <td>30'</td>
          </tr>
          <tr>
            <td>11</td>
            <td>E5A1</td>
            <td>Enumeration</td>
            <td>45'</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ActionsTable;
