import React, { useEffect, useRef, useState } from 'react';
import { Timeline as VisTimeline, DataSet, TimelineOptions } from 'vis-timeline/standalone';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import './Timeline.css'; 

const TimelineComponent: React.FC = () => {
  const timelineRef = useRef<HTMLDivElement>(null);
  const [timeline, setTimeline] = useState<VisTimeline | null>(null);
  const [startTime, setStartTime] = useState(10);
  const [endTime, setEndTime] = useState(50);

  useEffect(() => {
    if (timelineRef.current && !timeline) {
      const items = new DataSet([
        { id: 1, content: 'E1 - Apache Server', start: 0, end: 15, className: 'custom-item' },
        { id: 2, content: 'E2 - Simulated User', start: 20, end: 40, className: 'custom-item' },
        { id: 3, content: 'E2A1 - Scanning the Network', start: 25, end: 35, className: 'custom-item' },
        { id: 4, content: 'E2A2 - Exploiting a Vulnerability', start: 45, end: 50, className: 'custom-item' }
      ]);

      const options: TimelineOptions = {
        width: '100%',
        height: '200px',
        min: 0,
        max: 120,
        start: 0,
        end: 120,
        zoomable: false,
        orientation: { axis: 'top' },
        editable: false,
      };

      const newTimeline = new VisTimeline(timelineRef.current, items, options);
      setTimeline(newTimeline);

      const addVerticalLine = (id: string, initialPosition: number, className: string) => {
        const line = document.createElement('div');
        line.className = className;
        line.style.position = 'absolute';
        line.style.height = '100%';
        line.style.width = '2px';
        line.style.top = '0';
        line.style.left = `${(initialPosition / 120) * 100}%`;
        line.style.backgroundColor = className === 'start-line' ? 'blue' : 'red';
        line.style.cursor = 'pointer';

        let isDragging = false;

        line.addEventListener('mousedown', () => {
          isDragging = true;
        });

        document.addEventListener('mousemove', (e) => {
          if (isDragging) {
            const rect = timelineRef.current!.getBoundingClientRect();
            const newPosition = ((e.clientX - rect.left) / rect.width) * 120;
            if (id === 'start-line') {
              const boundedPosition = Math.max(0, Math.min(newPosition, endTime));
              setStartTime(boundedPosition);
              line.style.left = `${(boundedPosition / 120) * 100}%`;
            } else {
              const boundedPosition = Math.max(startTime, Math.min(newPosition, 120));
              setEndTime(boundedPosition);
              line.style.left = `${(boundedPosition / 120) * 100}%`;
            }
          }
        });

        document.addEventListener('mouseup', () => {
          isDragging = false;
        });

        timelineRef.current!.appendChild(line);
      };

      addVerticalLine('start-line', startTime, 'start-line');
      addVerticalLine('end-line', endTime, 'end-line');

      // Hide major labels
      const hideMajorLabels = () => {
        const majorLabels = document.querySelectorAll('.vis-time-axis .vis-major');
        majorLabels.forEach((label) => {
          (label as HTMLElement).style.display = 'none';
        });
      };

      if (newTimeline) {
        hideMajorLabels();
      }
    }
  }, [timeline, startTime, endTime]);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: '-40px', // Adjust this value to move the controls higher
          right: 0,
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          paddingBottom: '10px', // Add space between controls and timeline
        }}
      >
        <label>Start Time (minutes): </label>
        <input
          type="number"
          value={Math.round(startTime)}
          onChange={(e) => setStartTime(parseInt(e.target.value))}
          style={{ width: '50px' }}
        />
        <label>End Time (minutes): </label>
        <input
          type="number"
          value={Math.round(endTime)}
          onChange={(e) => setEndTime(parseInt(e.target.value))}
          style={{ width: '50px' }}
        />
      </div>
      <div ref={timelineRef} style={{ position: 'relative', width: '100%', marginTop: '40px' }}></div>
    </div>
  );
};

export default TimelineComponent;

