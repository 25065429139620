// FinalReportPDF.tsx
import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

// Define styles for the PDF
const styles = StyleSheet.create({
  page: { padding: 20 },
  section: { marginBottom: 10 },
  title: { fontSize: 24, marginBottom: 10 },
  subtitle: { fontSize: 18, marginBottom: 6 },
  listItem: { fontSize: 14, marginBottom: 4 },
  columnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  column: {
    width: '30%',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    backgroundColor: '#f9f9f9',
  },
  found: { color: 'green' },
  avoided: { color: 'red' },
  descriptionText: { fontSize: 12, marginBottom: 4 },
  link: { color: 'blue', textDecoration: 'underline' },
});

const FinalReportPDF: React.FC = () => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>Final Report</Text>
      </View>

      <View style={styles.columnContainer}>
       
        <View style={styles.column}>
          <Text style={styles.subtitle}>Found Vulnerabilities</Text>
          <Text style={[styles.listItem, styles.found]}>CWE-4756 (Student 2)</Text>
          <Text style={[styles.listItem, styles.found]}>CWE-3354 (Student 4)</Text>
          <Text style={[styles.listItem, styles.found]}>CWE-9048 (Student 4)</Text>
          <Text style={[styles.listItem, styles.found]}>CWE-1180 (Student 5)</Text>
          <Text style={[styles.listItem, styles.found]}>CWE-9379 (Student 3)</Text>
          <Text style={[styles.listItem, styles.found]}>CWE-78 (Student 4)</Text>
          <Text style={[styles.listItem, styles.found]}>CWE-14712 (Student 5)</Text>
          <Text style={[styles.listItem, styles.found]}>CWE-9976 (Student 4)</Text>
        </View>

        
        <View style={styles.column}>
          <Text style={styles.subtitle}>Not Found Vulnerabilities</Text>
          <Text style={styles.listItem}>CWE-47567</Text>
          <Text style={styles.listItem}>CWE-33594</Text>
          <Text style={styles.listItem}>CWE-90448</Text>
          <Text style={styles.listItem}>CWE-118</Text>
          <Text style={styles.listItem}>CWE-979</Text>
          <Text style={styles.listItem}>CWE-21</Text>
          <Text style={styles.listItem}>CWE-147123</Text>
          <Text style={styles.listItem}>CWE-987654</Text>
        </View>

        
        <View style={[styles.column, { borderColor: 'red' }]}>
          <Text style={styles.subtitle}>Avoided Vulnerabilities</Text>
          <Text style={[styles.listItem, styles.avoided]}>CWE-4756</Text>
          <Text style={[styles.listItem, styles.avoided]}>CWE-33544</Text>
          <Text style={[styles.listItem, styles.avoided]}>CWE-9048</Text>
          <Text style={[styles.listItem, styles.avoided]}>CWE-1180</Text>
          <Text style={[styles.listItem, styles.avoided]}>CWE-14712</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>Strategy Description</Text>
        <Text style={styles.descriptionText}>
          The exercise starts after a Network management team has noticed unusual traffic and connections going through the network from unusual ports, specifically from DMZone to the internet. The <Text style={{ fontWeight: 'bold' }}>Network management</Text> team fixed the mis-configuration of the firewall by creating new rules to allow only HTTP connections from port 80 and 443. Today the company website is defaced with a message announcing a <Text style={{ fontWeight: 'bold' }}>Data Breach</Text>. The most sensitive data of a specific server is encrypted. Trainees have to analyze the network logs to find what occurred, how the Data server was breached, and advise on the appropriate actions.
        </Text>
        <Text style={styles.descriptionText}>
          Donec ullamcorper nulla non metus auctor fringilla. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Nullam quis risus eget urna mollis ornare vel eu leo.
        </Text>
        <Text style={styles.descriptionText}>
          Donec ullamcorper nulla non metus auctor fringilla. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Nullam quis risus eget urna mollis ornare vel eu leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Text>
        
      </View>
    </Page>
  </Document>
);

export default FinalReportPDF;
