import React from 'react';
import Machine1Table from '../../tables/Machine1Table';
import Machine2Table from '../../tables/Machine2Table';
import Machine3Table from '../../tables/Machine3Table';
import './Scoring.css';

const Scoring = () => {
  return (
    <div className="scoring-view">
      <h2>Scoring View</h2>
      <div className="scoring-overview">
        <div className="scoring-box">
          <img src="/vulnerabilities-found-icon.png" alt="Vulnerabilities Found" />
          
        </div>
        <div className="scoring-box">
          <img src="/vulnerabilities-patched-icon.png" alt="Vulnerabilities Patched" />
          
        </div>
        <div className="criticality-box">
          <h3>Criticality Score</h3>
          <div className="criticality-score">8</div>
        </div>
      </div>

      
      <Machine1Table />
      <Machine2Table />
      <Machine3Table />
    </div>
  );
};

export default Scoring;
