import React, { useState } from 'react';
import './Table.css';

const Machine1Table = () => {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const toggleDetails = () => {
    setIsDetailsVisible(!isDetailsVisible);
  };

  return (
    <div className="table-container">
      <h3>Machine 1 LinuxSrv-VM-02</h3>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>Name of Vulnerability</th>
            <th>CVSS Score</th>
            <th>Found</th>
            <th>Exploited</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>CWE-787 Out-of-bounds Write</td>
            <td>9.8</td>
            <td className="fail">Fail</td>
            <td className="fail">Fail</td>
          </tr>
          <tr onClick={toggleDetails} style={{ cursor: 'pointer' }}>
            <td>2.</td>
            <td>CWE-476 NULL Pointer Dereference</td>
            <td>7.5</td>
            <td className="success">Success</td>
            <td className="success">Success</td>
          </tr>
          {isDetailsVisible && (
            <tr>
              <td colSpan={5}>
                <div className="details-container">
                  <div className="details">
                    <p><strong>Methods:</strong></p>
                    <div className="tags">
                      <span className="tag green">Directory Enumeration</span>
                      <span className="tag grey">Fuzzing</span>
                      <span className="tag blue">Fingerprinting</span>
                      <span className="tag red">Brute-force</span>
                    </div>
                    <p><strong>Tools:</strong> nmap, dirb, gobuster, dirbuster</p>
                    <p><strong>Technique:</strong> Simulated User, Reaction, Timed</p>
                    <p><strong>Imported after event?</strong> <input type="checkbox" checked /></p>
                    <p><strong>CVSS Score:</strong> 7.5</p>
                    <p><strong>Exploited:</strong> <span className="success">Successful</span> <span className="fail">Failed</span></p>
                    <p><strong>Status:</strong> <span className="status ongoing">Ongoing</span></p>
                    <p><strong>Compromised after hint?</strong> <input type="checkbox" checked /></p>
                    <p><strong>Feedback:</strong></p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque molestie eros ligula, dapibus pellentesque nibh dapibus nec. Quisque bibendum tortor a ligula eleifend blandit. Morbi a mauris quam. Morbi molestie augue libero, nec dictum sapien facilisis sed. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada, ante eget euismod consectetur, libero neque interdum ligula, ac mattis odio metus et leo. Nullam ligula dui, pulvinar at bibendum at, mollis sed lectus. Maecenas vehicula dolor et magna finibus, eu dignissim nulla scelerisque. </p>
                    <p><a href="/nmap_result.png">Nmap_result.png</a> <a href="/nmap_analysis.png">Nmap_analysis.png</a></p>
                  </div>
                </div>
              </td>
            </tr>
          )}
          <tr>
            <td>3.</td>
            <td>CWE-79 Improper Neutralization of Input (XSS)</td>
            <td>4.3</td>
            <td className="success">Success</td>
            <td className="success">Success</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Machine1Table;
